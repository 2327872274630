import styles from "./styles.module.css";
import {
  CoinMCIcon,
  EtherScanLogo,
  HearIcon,
  HekonIcon,
  MountainsIcon,
} from "assets/svg";
import sleepImg from "assets/images/image10.png";
import sleepImgDesktop from "assets/images/sleep-desktop.png";
import { useDeviceType } from "hooks";
import { CustomHeadline, CustomText } from "components/ui";

export const OurMissions = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CustomHeadline
          size={isDesktop ? 42 : 24}
          text="Learn 4x Faster with the #1 Night Learning App, Powered by AI,
          Science, and Web3"
        />
        <CustomText
          size={isDesktop ? 20 : 16}
          text="Unlock the Sleep-n-Learn-to-Earn experience with $SCRB tokens. Join
          the pre-sale today and be part of the future of learning."
        />
        <>
          <div className={styles.icons}>
            <CoinMCIcon
              width={isDesktop ? 40 : 30}
              height={isDesktop ? 40 : 30}
            />
            <HekonIcon
              width={isDesktop ? 40 : 30}
              height={isDesktop ? 40 : 30}
            />
            <EtherScanLogo
              width={isDesktop ? 40 : 30}
              height={isDesktop ? 40 : 30}
            />
            <MountainsIcon
              width={isDesktop ? 40 : 30}
              height={isDesktop ? 40 : 30}
            />
            <HearIcon
              width={isDesktop ? 40 : 30}
              height={isDesktop ? 40 : 30}
            />
          </div>
          <CustomText
            size={isDesktop ? 20 : 16}
            text=" Discover MindScriber on Trusted Platforms"
          />
        </>
      </div>
      {isDesktop ? (
        <div className={styles.imageDesktopWrapper}>
          <img
            className={styles.imageDesktop}
            src={sleepImgDesktop}
            alt="Sleep"
          />
        </div>
      ) : (
        <img className={styles.image} src={sleepImg} alt="Sleep" />
      )}
    </div>
  );
};
