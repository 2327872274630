import { useDispatch, useSelector } from "react-redux";
import { closeModal, RootState } from "store";
import styles from "./styles.module.css";
import { ILink } from "types/navigation";
import { LINKS, LINKS_DESKTOP } from "constants/links";
import { Dispatch, SetStateAction } from "react";
import { ModalSocials } from "components/modal-socials";
import { useDeviceType } from "hooks";

interface IProps {
  setTranslate: Dispatch<SetStateAction<number>>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

export const Modal = ({ setTranslate, setActiveSection }: IProps) => {
  const { device } = useDeviceType();
  const dispatch = useDispatch();
  const isOpen = useSelector(({ modal }: RootState) => modal.isOpen);
  if (!isOpen) return null;

  const navigateTo = (id: number) => {
    setTranslate(id * window.innerHeight);
    setActiveSection(id);
    dispatch(closeModal());
  };
  const navigateDesktopTo = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    dispatch(closeModal());
  };

  return (
    <div className={styles.container}>
      <ul className={styles.modalContent}>
        {(device === "desktop" ? LINKS_DESKTOP : LINKS).map(
          (link: ILink, index) => {
            return (
              <li
                key={link.value}
                onClick={() =>
                  device === "desktop"
                    ? navigateDesktopTo(link.id)
                    : navigateTo(index)
                }
              >
                {link.value}
              </li>
            );
          }
        )}
        <div className={styles.socContainer}>
          <ModalSocials />
        </div>
      </ul>
      <div className={styles.blur} onClick={() => dispatch(closeModal())} />
    </div>
  );
};
