import styles from "./styles.module.css";
interface CustomButtonProps {
  text: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}

export const CustomButton = ({
  text,
  type = "button",
  style,
  icon,
}: CustomButtonProps) => (
  <button type={type} className={styles.button} style={style}>
    {icon && <span className={styles.icon}>{icon}</span>}
    {text}
  </button>
);
