import { SVGProps } from "react";

export const FitnesTrackerIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
     <mask
      id="mask0_13436_1134"
      width="24"
      height="25"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 .156h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1134)">
      <path
        fill="#000"
        d="m9 22.156-1.2-3.975a2.97 2.97 0 0 1-.8-2.025v-8a2.97 2.97 0 0 1 .8-2.05L9 2.156h6l1.15 3.925q.4.425.625.95T17 8.156v8a2.97 2.97 0 0 1-.825 2.075L15 22.156zm1.475-2H13.5l.3-1h-3.625zm-.475-3h4q.424 0 .713-.287a.97.97 0 0 0 .287-.713v-8a.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288h-4a.97.97 0 0 0-.713.288.97.97 0 0 0-.287.712v8q0 .425.287.713.288.287.713.287m.175-12H13.8l-.3-1h-3.025z"
      ></path>
    </g>
  </svg>
);
