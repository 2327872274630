import { SVGProps } from "react";

export const BurgerMenuIcon = ({
  width = 40,
  height = 32,
  fill = "#28793E",
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 40 32"
  >
    <path fill={fill} d="M0 .5h40v4H0zM0 14h40v4H0zM0 27.5h40v4H0z"></path>
  </svg>
);
