import { CSSProperties, ReactNode } from "react";
import styles from "./styles.module.css";

interface SectionProps {
  id: string;
  title?: string;
  style?: CSSProperties;
  children: ReactNode;
}

export const SectionContainer = ({
  id,
  title,
  children,
  style,
}: SectionProps) => {
  return (
    <section id={id} className={styles.container} style={style}>
      <h2 className={styles.title} style={{ paddingLeft: style ? "5%" : 0 }}>
        {title}
      </h2>
      {children}
    </section>
  );
};
