import { DESKTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from "constants/breakpoints";
import { useState, useEffect } from "react";

type DeviceType = "mobile" | "tablet" | "desktop";

const useDeviceType = () => {
  const [device, setDevice] = useState<DeviceType>("mobile");
  const [deviceDimensions, setDeviceDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: DESKTOP_MIN_WIDTH, height: 500 });

  useEffect(() => {
    const handleDeviceDetection = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setDeviceDimensions({ width, height });

      if (width < TABLET_MIN_WIDTH) {
        setDevice("mobile");
      } else if (width < DESKTOP_MIN_WIDTH) {
        setDevice("tablet");
      } else {
        setDevice("desktop");
      }
    };
    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, [device]);

  return { device, deviceDimensions };
};

export { useDeviceType };
