import { SVGProps } from "react";

export const TextToImageIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1095"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1095)">
      <path
        fill="#1C1B1F"
        d="M5 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 19V5q0-.824.587-1.412A1.93 1.93 0 0 1 5 3h14q.824 0 1.413.587Q21 4.176 21 5v14q0 .824-.587 1.413A1.93 1.93 0 0 1 19 21zm0-2h14V5H5zm1-2h12l-3.75-5-3 4L9 13zm2.5-7q.624 0 1.063-.437Q10 9.125 10 8.5t-.437-1.062A1.45 1.45 0 0 0 8.5 7q-.625 0-1.062.438A1.45 1.45 0 0 0 7 8.5q0 .624.438 1.063Q7.875 10 8.5 10"
      ></path>
    </g>
  </svg>
);
