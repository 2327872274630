import React, {
  useRef,
  TouchEvent,
  useCallback,
  ReactNode,
  Children,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import styles from "./styles.module.css";
import "./desktop.css";
import { useDeviceType } from "hooks";

interface ScrollableContainerProps {
  children: ReactNode;
  snapSensitivity: number; // Controls swipe sensitivity (default is 50px)
  itemSpacing: number;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setTranslate: Dispatch<SetStateAction<number>>;
  translate: number;
}

export const ScrollableContainer = ({
  children,
  snapSensitivity = 50,
  itemSpacing = 0,
  activeSection,
  setActiveSection,
  setTranslate,
  translate,
}: ScrollableContainerProps) => {
  const { device } = useDeviceType();

  const sectionHeight = window.innerHeight;
  const thumbRef = useRef<HTMLDivElement | null>(null);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const touchStartY = useRef<number>(0);
  const currentTranslate = useRef<number>(translate);

  const sectionsAmount = Children.count(children);

  const handleTouchStart = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      touchStartY.current = e.touches[0].clientY;
      currentTranslate.current = translate;
    },
    [translate]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      const touchCurrentY = e.touches[0].clientY;
      const distance = touchStartY.current - touchCurrentY;
      const newTranslate = currentTranslate.current + distance;

      // Constrain movement within the valid range
      const maxTranslate = sectionHeight * (sectionsAmount - 1);
      setTranslate(Math.max(0, Math.min(newTranslate, maxTranslate)));
    },
    [sectionsAmount, sectionHeight, setTranslate]
  );

  const handleTouchEnd = useCallback(() => {
    const totalSectionHeight = sectionHeight * activeSection;
    const snapDifference = translate - totalSectionHeight;
    if (Math.abs(snapDifference) < snapSensitivity) {
      setTranslate(activeSection * sectionHeight);
    } else {
      setActiveSection((prev) => (snapDifference > 0 ? prev + 1 : prev - 1));
      setTranslate(
        (snapDifference > 0 ? activeSection + 1 : activeSection - 1) *
          sectionHeight
      );
    }
  }, [translate, sectionHeight, setActiveSection, setTranslate]);

  if (device === "desktop") {
    return (
      <div className="scroll-container">
        {Children.map(children, (child, index) => {
          if (child)
            return (
              <section key={index} className="scroll-section">
                <div>{child}</div>
              </section>
            );
        })}
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={styles.content}
        style={{
          transform: `translateY(-${translate}px)`,
          gap: itemSpacing,
        }}
      >
        {Children.map(children, (child, index) => (
          <div className={styles.section} id="section" key={index}>
            {child}
          </div>
        ))}
      </div>
      <div ref={scrollbarRef} className={styles.scrollbar}>
        <div
          ref={thumbRef}
          className={styles.scrollbarThumb}
          style={{
            height: sectionHeight / sectionsAmount,
            transform: `translateY(${translate / sectionsAmount}px)`,
          }}
        />
      </div>
    </div>
  );
};
