import { SVGProps } from "react";

export const SpeedIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1125"
      width="20"
      height="21"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 .156h20v20H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1125)">
      <path
        fill="#000"
        d="M8.708 13.072q.5.5 1.291.49.792-.012 1.167-.574l4.667-7-7 4.667q-.562.375-.594 1.146t.469 1.27m1.291-9.584q1.23 0 2.365.344t2.135 1.031l-1.583 1a6.376 6.376 0 0 0-2.917-.708q-2.77 0-4.718 1.948t-1.948 4.719q0 .875.24 1.729.239.854.676 1.604h11.5a6 6 0 0 0 .698-1.646 7 7 0 0 0 .219-1.77 6 6 0 0 0-.708-2.834l1-1.583q.624.979.99 2.083a7.7 7.7 0 0 1 .385 2.292 8.2 8.2 0 0 1-.271 2.27 8.6 8.6 0 0 1-.854 2.063q-.23.375-.625.583a1.77 1.77 0 0 1-.834.209H4.25q-.437 0-.833-.209a1.6 1.6 0 0 1-.625-.583 8.27 8.27 0 0 1-1.125-4.208 8 8 0 0 1 .656-3.24 8.5 8.5 0 0 1 1.792-2.646A8.5 8.5 0 0 1 6.77 4.145 8.1 8.1 0 0 1 10 3.488"
      ></path>
    </g>
  </svg>
);
