import { CustomText } from "components/ui";
import styles from "./styles.module.css";
import chartImg from "assets/images/token-chart.png";
import { useDeviceType } from "hooks";

export const TokenDistribution = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <CustomText
          size={isDesktop ? 20 : 16}
          text={
            <>
              <b>Total Supply:</b> 10 billion tokens
            </>
          }
        />
        <div className={styles.imgContainer}>
          <img src={chartImg} alt="chart" className={styles.chartImg} />
        </div>
      </div>
    </div>
  );
};
