import SliderImg1 from "assets/images/team-1.png";
import SliderImg2 from "assets/images/team-2.png";
import SliderImg3 from "assets/images/team-3.png";
import SliderImg4 from "assets/images/team-4.png";
import SliderImg5 from "assets/images/team-5.png";
import SliderImg6 from "assets/images/team-6.png";
import AdvisorsImg1 from "assets/images/advisors-1.png";
import AdvisorsImg2 from "assets/images/advisors-2.png";
import AdvisorsImg3 from "assets/images/advisors-3.png";
import AdvisorsImg4 from "assets/images/advisors-4.png";
import AdvisorsImg5 from "assets/images/advisors-5.png";

export const CARD_DATA = [
  {
    title: "Igar Dyachenko",
    subTitle: "Co-Founder, CEO",
    description:
      "A dyslexic 2x Karate World Champ, 4x Founder, drives neurodivergent empowerment.",
    imgSrc: SliderImg1,
  },
  {
    title: "Dr. Mark Litvak",
    subTitle: "Chief Science Officer",
    description:
      "A neuroscientist pushing cognitive performance breakthroughs.",
    imgSrc: SliderImg2,
  },
  {
    title: "Alexander Riftine",
    subTitle: "Chief Algorithm Officer",
    description:
      "Specialist in integrating health monitoring and neurotech systems.",
    imgSrc: SliderImg3,
  },
  {
    title: "Marta Haievyshyn",
    subTitle: "Chief Design Officer",
    description: "UX leader crafting intuitive, learner-friendly designs.",
    imgSrc: SliderImg4,
  },
  {
    title: "Omer Cohen",
    subTitle: "Chief Technology Officer",
    description: "Full Stack developer advancing innovative learning tools.",
    imgSrc: SliderImg5,
  },
  {
    title: "Oleg Kryvak",
    subTitle: "Lead Mobile Developer",
    description: "Specialist in mobile ecosystems for neurodivergent tools.",
    imgSrc: SliderImg6,
  },
];

export const ADVISORS: Array<any> = [
  AdvisorsImg1,
  AdvisorsImg2,
  AdvisorsImg3,
  AdvisorsImg4,
  AdvisorsImg5,
];
