import TeamLinkedInImg2 from "assets/images/team-link-2.png";
import TeamLinkedInImg3 from "assets/images/team-link-3.png";

export const TEAM_LINKEDIN = [
  {
    title: "Daryna Parastatova",
    subTitle: "Social Media Marketer",
    imgSrc: TeamLinkedInImg2,
    link: "https://www.linkedin.com/in/parastatova/",
  },
  {
    title: "Oleg Kryvak ",
    subTitle: "Lead Moblie Developer",
    imgSrc: TeamLinkedInImg3,
    link: "https://www.linkedin.com/in/oleg-kryvak/",
  },
];
