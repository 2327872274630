import { SVGProps } from "react";

export const ArrowIcon = ({
  width = 14,
  height = 8,
  fill = "#000",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 14 8"
    style={style}
  >
    <path
      fill={fill}
      d="M7 8 0 1.093 1.108 0 7 5.814 12.892 0 14 1.093z"
    ></path>
  </svg>
);
