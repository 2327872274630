import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.css";
import { CustomButton, CustomInput } from "components";
import { db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { RocketLuncher, SuccessIcon, FaildIcon } from "assets/svg";

export const ContactForm = () => {
  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean | null;
    message: string;
  }>({ success: null, message: "" });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await addDoc(collection(db, "contacts"), {
          ...values,
          createdAt: new Date(),
        });
        resetForm();
        setSubmitStatus({
          success: true,
          message: "Your message is on its way.",
        });
      } catch (error) {
        console.error("Error saving form data to Firestore: ", error);
        setSubmitStatus({
          success: false,
          message: "Message not sent. Try again.",
        });
      }
    },
  });

  // Dynamic text and icon based on submitStatus
  const getButtonProps = () => {
    if (submitStatus.success === true) {
      return { text: "Your message is on its way.", icon: <SuccessIcon /> };
    } else if (submitStatus.success === false) {
      return { text: "Message not sent. Try again.", icon: <FaildIcon /> };
    } else {
      return { text: "Send it over", icon: <RocketLuncher /> };
    }
  };

  const buttonProps = getButtonProps();

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <CustomInput
        placeholder="Full Name"
        name="fullName"
        value={formik.values.fullName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.fullName}
        touched={formik.touched.fullName}
      />
      <CustomInput
        placeholder="Email"
        name="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email}
        touched={formik.touched.email}
      />
      <CustomInput
        placeholder="Subject"
        name="subject"
        value={formik.values.subject}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.subject}
        touched={formik.touched.subject}
      />
      <div className={styles.formControl}>
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${styles.textarea} ${
            formik.errors.message && formik.touched.message ? styles.error : ""
          }`}
        />
        {formik.errors.message && formik.touched.message && (
          <div className={styles.errorText}>{formik.errors.message}</div>
        )}
      </div>
      <CustomButton
        text={buttonProps.text}
        type="submit"
        disabled={!(formik.isValid && formik.dirty)}
        style={{
          backgroundColor:
            submitStatus.success === true
              ? "#D3F1E2"
              : submitStatus.success === false
              ? "#FCCCCC"
              : "",
          color:
            submitStatus.success === true
              ? "#28793E"
              : submitStatus.success === false
              ? "#E20909"
              : "",
          marginTop: "2.5%",
        }}
        icon={buttonProps.icon}
      />
    </form>
  );
};
