import { SVGProps } from "react";

export const CrossBigIcon = ({
  width = 40,
  height = 32,
  fill = "#28793E",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 40 32"
  >
    <path
      fill={fill}
      d="m6.918.797 28.284 28.284-2.828 2.829L4.089 3.625z"
    ></path>
    <path
      fill={fill}
      d="M4.797 29.082 33.081.798l2.829 2.828L7.625 31.91z"
    ></path>
  </svg>
);
