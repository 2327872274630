import { SVGProps } from "react";

export const LineIcon = ({
  width = 1,
  height = 34,
  fill = "#28793E",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 0V34" stroke="black" />
  </svg>
);
