import React, { useRef, useState, useEffect } from "react";
import styles from "./style.module.css";

type CardProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  imageUrl?: string;
  IconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  imageSize?: "small" | "medium" | "large";
  cardHeight?: number | string;
};

const OmerCard: React.FC<CardProps> = ({
  title,
  subtitle,
  description,
  imageUrl,
  IconComponent,
  imageSize = "medium",
  cardHeight,
}) => {
  const [cardWidth, setCardWidth] = useState(0);
  const cardRef = useRef<HTMLDivElement | null>(null);

  // Update card width after component mounts
  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, []);

  const imageClass = styles[`image_${imageSize}`];

  return (
    <div
      className={styles.cardContainer}
      ref={cardRef}
      style={{ height: cardHeight }}
    >
      <div className={styles.contentWrapper}>
        {title && (
          <div className={styles.titleWrapper}>
            <div>
              {IconComponent && <IconComponent className={styles.icon} />}
            </div>
            <h2 className={styles.title}>{title}</h2>
          </div>
        )}
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        {description && (
          <div className={styles.descriptionWrapper}>
            <p className={styles.description}>{description}</p>
          </div>
        )}
      </div>
      <div className={styles.imageWrapper}>
        <img
          className={`${styles.image} ${imageClass}`}
          src={imageUrl}
          alt="Card"
        />
      </div>
    </div>
  );
};

export default OmerCard;
