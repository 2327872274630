import { createSlice } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  buyToken: boolean;
}

const initialState: ModalState = {
  isOpen: false,
  buyToken: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    toggleModal: (state) => {
      state.isOpen = !state.isOpen;
    },
    toggleBuyToken: (state) => {
      state.buyToken = !state.buyToken;
    },
  },
});

export const { openModal, closeModal, toggleModal, toggleBuyToken } =
  modalSlice.actions;

export default modalSlice.reducer;
