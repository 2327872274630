import { SVGProps } from "react";

export const PowerIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13476_1680"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13476_1680)">
      <path
        fill="#1C1B1F"
        d="M11.5 19h1v-1.85l3.5-3.5V9H8v4.65l3.5 3.5zm-2 2v-3L6 14.5V9q0-.825.588-1.412A1.93 1.93 0 0 1 8 7h1L8 8V3h2v4h4V3h2v5l-1-1h1q.824 0 1.413.588Q18 8.175 18 9v5.5L14.5 18v3z"
      ></path>
    </g>
  </svg>
);
