import { useDispatch, useSelector } from "react-redux";
import { RootState, toggleBuyToken } from "store";
import styles from "./styles.module.css";
import { BuyTokenForm } from "components";
import { useState } from "react";
import Joined from "assets/images/joined.png";
import { CrossIcon } from "assets/svg";

export const BuyTokenModal = () => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const isOpen = useSelector(({ modal }: RootState) => modal.buyToken);
  if (!isOpen) return;

  return (
    <div className={styles.container}>
      <div className={styles.modalContent}>
        <button
          onClick={() => dispatch(toggleBuyToken())}
          className={styles.crossBtn}
        >
          <CrossIcon />
        </button>
        <h2 className={styles.title}>
          {isConnected
            ? "Thank You for Joining the Scriber ($SCRB) Whitelist!"
            : "Join the Scriber ($SCRB) Whitelist"}
        </h2>
        <h3 className={styles.subTitle}>
          {isConnected
            ? "You’re now part of the exclusive group with early access to $SCRB tokens."
            : "Gain exclusive early access to $SCRB tokens before the public sale."}
        </h3>
        {!isConnected ? (
          <BuyTokenForm setIsConnected={setIsConnected} />
        ) : (
          <div className={styles.connected}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                Check your email for the confirmation link to complete your
                whitelist registration.
              </li>
              <li className={styles.listItem}>
                Make sure your ETH wallet is ready.
              </li>
            </ul>
            <img src={Joined} alt="Joined" className={styles.joinedImage} />
          </div>
        )}
      </div>
      <div className={styles.blur} onClick={() => dispatch(toggleBuyToken())} />
    </div>
  );
};
