import { SVGProps } from "react";

export const BedTimeIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={width}
  height={height}
  fill="none"
  viewBox="0 0 24 24"
>
  <mask
    id="mask0_13410_18083"
    width="24"
    height="24"
    x="0"
    y="0"
    maskUnits="userSpaceOnUse"
    style={{ maskType: "alpha" }}
  >
    <path fill={fill} d="M0 0h24v24H0z"></path>
  </mask>
  <g mask="url(#mask0_13410_18083)">
    <path
      fill="#1C1B1F"
      d="M14.1 22q-2.1 0-3.937-.8a10.3 10.3 0 0 1-3.2-2.162 10.3 10.3 0 0 1-2.163-3.2A9.8 9.8 0 0 1 4 11.9q0-3.65 2.325-6.437T12.25 2a10.1 10.1 0 0 0 .275 4.838 9.87 9.87 0 0 0 2.5 4.137 9.87 9.87 0 0 0 4.138 2.5A10.1 10.1 0 0 0 24 13.75q-.65 3.6-3.45 5.925T14.1 22m0-2a7.9 7.9 0 0 0 4.075-1.1 7.9 7.9 0 0 0 2.95-3.025 12.4 12.4 0 0 1-4.075-1.088 11.9 11.9 0 0 1-3.45-2.412 12.1 12.1 0 0 1-2.425-3.45A11.8 11.8 0 0 1 10.1 4.85a7.8 7.8 0 0 0-3.012 2.963A8.05 8.05 0 0 0 6 11.9q0 3.376 2.363 5.737Q10.725 20 14.1 20"
    ></path>
  </g>
</svg>
);
