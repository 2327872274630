import styles from "./styles.module.css";
import hand from "assets/images/hand.png";
import { SecureToken } from "assets/svg";
import { CustomHeadline, CustomText } from "components/ui";
import { DESKTOP_MIN_WIDTH } from "constants/breakpoints";
import { useDeviceType } from "hooks";
import { useDispatch } from "react-redux";
import { toggleBuyToken } from "store";

export const Challenge = () => {
  const { device } = useDeviceType();
  const dispatch = useDispatch();

  const handleBuyTokenClick = () => {
    dispatch(toggleBuyToken());
  };

  const isDesktop = device === "desktop";

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CustomHeadline
          styles={{ paddingTop: isDesktop ? 36 : 0 }}
          size={isDesktop ? 42 : 24}
          text="Traditional Learning Doesn’t Work for Everyone"
        />
        <CustomText
          size={isDesktop ? 20 : 16}
          text={
            isDesktop
              ? `Dyslexia affects 10% of children globally, yet only 5% receive the educational support they need. For neurodivergent students, who make up 20% of the population, 74% of struggling readers in third grade continue to face challenges in high school, highlighting a systemic failure to address learning differences.`
              : `Dyslexia affects 10% of children globally, but only 5% receive needed
          support. For 20% who are neurodivergent, 74% of struggling readers
          remain poor readers, exposing systemic failures in addressing learning
          challenges.`
          }
        />
        {isDesktop && (
          <CustomText
            size={20}
            text="Our AI-powered tools deliver 4x faster retention and enhanced engagement, whether awake or asleep."
          />
        )}

        <button
          className={isDesktop ? styles.desktopBtn : styles.btn}
          onClick={() => handleBuyTokenClick()}
        >
          <SecureToken className={styles.icon} />
          <span>Secure Tokens</span>
        </button>
      </div>
      <img className={styles.hand} src={hand} alt="hand" />
    </div>
  );
};
