import { SVGProps } from "react";

export const EmailIcon = ({
  width = 30,
  height = 24,
  fill = "#28793E",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 24C2.175 24 1.46875 23.7063 0.88125 23.1188C0.29375 22.5312 0 21.825 0 21V3C0 2.175 0.29375 1.46875 0.88125 0.88125C1.46875 0.29375 2.175 0 3 0H27C27.825 0 28.5312 0.29375 29.1188 0.88125C29.7063 1.46875 30 2.175 30 3V21C30 21.825 29.7063 22.5312 29.1188 23.1188C28.5312 23.7063 27.825 24 27 24H3ZM15 13.5L27 6V3L15 10.5L3 3V6L15 13.5Z"
      fill="#28793E"
    />
  </svg>
);
