import { SVGProps } from "react";

export const RocketLuncher = ({
  width = 20,
  height = 17,
  fill = "white",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17L14.6 15.6L16.175 14H12V12H16.175L14.6 10.4L16 9L20 13L16 17ZM2 14C1.45 14 0.979167 13.8042 0.5875 13.4125C0.195833 13.0208 0 12.55 0 12V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H15C15.55 0 16.0208 0.195833 16.4125 0.5875C16.8042 0.979167 17 1.45 17 2V7.1C16.8333 7.06667 16.6667 7.04167 16.5 7.025C16.3333 7.00833 16.1667 7 16 7C15.8333 7 15.6667 7.00417 15.5 7.0125C15.3333 7.02083 15.1667 7.04167 15 7.075V3.4L8.4 8L2 3.425V12H10.075C10.0417 12.1667 10.0208 12.3333 10.0125 12.5C10.0042 12.6667 10 12.8333 10 13C10 13.1667 10.0083 13.3333 10.025 13.5C10.0417 13.6667 10.0667 13.8333 10.1 14H2ZM3.45 2L8.4 5.55L13.5 2H3.45Z"
      fill="white"
    />
  </svg>
);
