import React, { ReactNode, useCallback, useState } from "react";
import styles from "./styles.module.css";
import {
  Navbar,
  Modal,
  ScrollableContainer,
  BuyTokenModal,
  PreventPullToRefresh,
} from "components";
import { NavId } from "types/navigation";
import { useDeviceType } from "hooks";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { device } = useDeviceType();
  const [activeSection, setActiveSection] = useState<number>(0);
  const [translate, setTranslate] = useState(0);

  const navigateToTop = useCallback(() => {
    if (device === "desktop") {
      document
        .getElementById(NavId.OurMission)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setTranslate(0);
    setActiveSection(0);
  }, [device]);

  return (
    <PreventPullToRefresh>
      <main className={styles.container}>
        <Navbar navigateToTop={navigateToTop} />
        <ScrollableContainer
          snapSensitivity={50}
          itemSpacing={0}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          setTranslate={setTranslate}
          translate={translate}
        >
          {children}
        </ScrollableContainer>
        <Modal
          setTranslate={setTranslate}
          setActiveSection={setActiveSection}
        />
        <BuyTokenModal />
      </main>
    </PreventPullToRefresh>
  );
};
