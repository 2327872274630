import { SVGProps } from "react";

export const AutoStoriesIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13476_1725"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13476_1725)">
      <path
        fill="#1C1B1F"
        d="M12 20a9.6 9.6 0 0 0-2.6-1.475A8.2 8.2 0 0 0 6.5 18q-1.05 0-2.062.275a9 9 0 0 0-1.938.775q-.525.275-1.012-.025A.97.97 0 0 1 1 18.15V6.1q0-.274.137-.525A.86.86 0 0 1 1.55 5.2q1.15-.6 2.4-.9T6.5 4q1.45 0 2.838.375Q10.725 4.75 12 5.5v12.1q1.275-.8 2.675-1.2a10.2 10.2 0 0 1 4.587-.25q.863.15 1.738.45v-12q.375.124.738.263.362.137.712.337.274.125.413.375.137.25.137.525v12.05a.97.97 0 0 1-.488.875q-.487.3-1.012.025a9 9 0 0 0-1.937-.775A7.8 7.8 0 0 0 17.5 18q-1.5 0-2.9.525A9.6 9.6 0 0 0 12 20m2-5V5.5l5-5v10zm-4 1.625v-9.9a9.3 9.3 0 0 0-1.713-.537A8.6 8.6 0 0 0 6.5 6q-.926 0-1.8.175A8.7 8.7 0 0 0 3 6.7v9.925A9.8 9.8 0 0 1 6.5 16q.9 0 1.762.15.863.15 1.738.475"
      ></path>
    </g>
  </svg>
);
