import OurStory1 from "assets/images/our-story-1.png";
import OurStory2 from "assets/images/our-story-2.png";
import OurStory3 from "assets/images/our-story-3.png";
import OurStory4 from "assets/images/our-story-4.png";
import OurStory5 from "assets/images/our-story-5.png";
import OurStory6 from "assets/images/our-story-6.png";

export const ICONS_DATA: Array<any> = [
  OurStory1,
  OurStory2,
  OurStory3,
  OurStory4,
  OurStory5,
  OurStory6,
];
