import { SVGProps } from "react";

export const ScanIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1143"
      width="24"
      height="25"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 .156h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1143)">
      <path
        fill="#000"
        d="M2 6.156v-5h5v2H4v3zm18 0v-3h-3v-2h5v5zm-18 17v-5h2v3h3v2zm15 0v-2h3v-3h2v5zm-10-5h10v-12H7zm0 2q-.824 0-1.412-.587A1.93 1.93 0 0 1 5 18.156v-12q0-.824.588-1.412A1.93 1.93 0 0 1 7 4.156h10q.824 0 1.413.588Q19 5.33 19 6.156v12q0 .825-.587 1.413a1.93 1.93 0 0 1-1.413.587zm2-10h6v-2H9zm0 3h6v-2H9zm0 3h6v-2H9z"
      ></path>
    </g>
  </svg>
);
