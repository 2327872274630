import { SVGProps } from "react";

export const MemoryIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1073"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1073)">
      <path
        fill="#1C1B1F"
        d="M9 15V9h6v6zm2-2h2v-2h-2zm-2 8v-2H7q-.824 0-1.412-.587A1.93 1.93 0 0 1 5 17v-2H3v-2h2v-2H3V9h2V7q0-.824.588-1.412A1.93 1.93 0 0 1 7 5h2V3h2v2h2V3h2v2h2q.824 0 1.413.588Q19 6.175 19 7v2h2v2h-2v2h2v2h-2v2q0 .824-.587 1.413A1.93 1.93 0 0 1 17 19h-2v2h-2v-2h-2v2zm8-4V7H7v10z"
      ></path>
    </g>
  </svg>
);
