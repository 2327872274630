import { useDispatch, useSelector } from "react-redux";
import styles from "./navbar.module.css";
import { ArrowIcon, BurgerMenuIcon, CrossBigIcon, CrossIcon } from "assets/svg";
import NavLogoIconNew from "assets/images/stack-2.png";
import { toggleBuyToken, toggleModal } from "store";
import { useDeviceType } from "hooks";
import { DESKTOP_MIN_WIDTH } from "constants/breakpoints";

interface IProps {
  navigateToTop: () => void;
}

export const Navbar = ({ navigateToTop }: IProps) => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";
  const dispatch = useDispatch();

  const isModalOpen = useSelector((state: any) => state.modal.isOpen);

  const handleMenuButtonClick = () => {
    dispatch(toggleModal());
  };

  const handleBuyTokenClick = () => {
    dispatch(toggleBuyToken());
  };

  return (
    <nav className={styles.container}>
      {isDesktop ? (
        <div onClick={navigateToTop} className={styles.logoContainer}>
          <img src={NavLogoIconNew} alt="icon" className={styles.logo} />
          <h2 className={styles.scrbTitle}>SCRB</h2>
        </div>
      ) : (
        <img
          onClick={navigateToTop}
          src={NavLogoIconNew}
          alt="icon"
          className={styles.logo}
        />
      )}

      <div className={styles.actions}>
        {!isDesktop && (
          <button
            className={styles.menuBtn}
            onClick={() => handleMenuButtonClick()}
          >
            <ArrowIcon
              style={{
                transform: isModalOpen ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease",
              }}
            />
          </button>
        )}

        <button className={styles.btn} onClick={handleBuyTokenClick}>
          Buy Tokens
        </button>
        {isDesktop && (
          <button
            className={styles.menuBtn}
            onClick={() => handleMenuButtonClick()}
          >
            {isModalOpen ? (
              <CrossBigIcon fill="#28793E" width={40} height={18} />
            ) : (
              <BurgerMenuIcon height={20}/>
            )}
          </button>
        )}
      </div>
    </nav>
  );
};
