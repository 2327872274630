import { CSSProperties } from "react";

import s from "./styles.module.css";
import { calculateResponsiveFontSize } from "utils";

interface IProps {
  text: string;
  size?: number;
  color?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  styles?: CSSProperties;
}

export const CustomHeadline = ({
  text,
  size = 24,
  color = "#000000",
  variant = "h2",
  styles,
}: IProps) => {
  const lineHeight = `${size - 2}px`;
  const fontSize = calculateResponsiveFontSize(size);

  switch (variant) {
    case "h1":
      return <h1 style={{ ...styles, fontSize, lineHeight, color }}>{text}</h1>;
    case "h2":
      return (
        <h2 style={{ ...styles, fontSize, lineHeight, color }} className={s.h2}>
          {text}
        </h2>
      );
    case "h3":
      return (
        <h3 style={{ ...styles, fontSize, lineHeight, color }} className={s.h3}>
          {text}
        </h3>
      );
  }
  return;
};
