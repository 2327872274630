import { SVGProps } from "react";

export const XIcon = ({
  width = 30,
  height = 30,
  fill = "#28793E",
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 41 40"
  >
    <g clipPath="url(#clip0_13431_578)">
      <path
        fill={fill}
        d="m17.55 25.402 9.292 12.933H40.5L25.167 16.99 37.924 1.668h-5.17l-9.985 11.99-8.61-11.99H.5l14.654 20.4L1.612 38.336h5.17zm11.243 8.859L8.305 5.742h3.902l20.488 28.519z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_13431_578">
        <path fill="#fff" d="M.5 0h40v40H.5z"></path>
      </clipPath>
    </defs>
  </svg>
);
