import "./App.css";
import { Provider } from "react-redux";
import { store } from "store";
import { NavId } from "types/navigation";
import {
  Challenge,
  Colaborations,
  GetInTouch,
  JourneyAhead,
  Layout,
  OurMissions,
  OurStory,
  SectionContainer,
  Team,
  TechFramework,
  Tokenomiks,
  WeOffer,
} from "components";
import { useDeviceType } from "hooks";

function App() {
  const { device } = useDeviceType();

  return (
    <Provider store={store}>
      <Layout>
        <SectionContainer id={NavId.OurMission}>
          <OurMissions />
        </SectionContainer>

        <SectionContainer
          id={NavId.OurStory}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <OurStory />
        </SectionContainer>

        <SectionContainer id={NavId.Challenge} style={{ paddingRight: "4dvw" }}>
          <Challenge />
        </SectionContainer>

        <SectionContainer id={NavId.TechFramework}>
          <TechFramework />
        </SectionContainer>

        <SectionContainer id={NavId.Tokenomics}>
          <Tokenomiks />
        </SectionContainer>

        <SectionContainer id={NavId.WhatWeOffer}>
          <WeOffer />
        </SectionContainer>

        <SectionContainer id={NavId.Journey}>
          <JourneyAhead />
        </SectionContainer>

        <SectionContainer
          id={NavId.Team}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Team />
        </SectionContainer>

        <SectionContainer
          id={NavId.GetInTouch}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <GetInTouch />
        </SectionContainer>
        {device !== "desktop" && (
          <SectionContainer id={NavId.Collaborations}>
            <Colaborations />
          </SectionContainer>
        )}
      </Layout>
    </Provider>
  );
}

export default App;
