import { Dispatch, SetStateAction, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.css";
import { CustomButton, CustomInput } from "components";
import { db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { PostIcon } from "assets/svg";

interface IProps {
  setIsConnected: Dispatch<SetStateAction<boolean>>;
}

export const BuyTokenForm = ({ setIsConnected }: IProps) => {
  const [submitStatus, setSubmitStatus] = useState<{
    success: boolean | null;
    message: string;
  }>({ success: null, message: "" });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      wallet: "",
      terms: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      wallet: Yup.string().required(
        "This field is mandatory, please enter your wallet address"
      ),
      terms: Yup.boolean().oneOf(
        [true],
        "You must agree to the Terms & Conditions."
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await addDoc(collection(db, "whitelist"), {
          ...values,
          createdAt: new Date(),
        });

        resetForm();
        setSubmitStatus({
          success: true,
          message: "Your message is on its way.",
        });
        setIsConnected(true);
      } catch (error) {
        console.error("Error saving form data to Firestore: ", error);
        setSubmitStatus({
          success: false,
          message: "Message not sent. Try again.",
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <CustomInput
        placeholder="Full Name"
        name="fullName"
        value={formik.values.fullName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.fullName}
        touched={formik.touched.fullName}
      />
      <CustomInput
        placeholder="Email"
        name="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email}
        touched={formik.touched.email}
      />
      <CustomInput
        placeholder="ETH Wallet Address"
        name="wallet"
        value={formik.values.wallet}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.wallet}
        touched={formik.touched.wallet}
      />
      <div className={styles.checkboxContainer}>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            name="terms"
            checked={formik.values.terms}
            onChange={formik.handleChange}
          />
          I agree to the Terms & Conditions and acknowledge that this is a
          whitelist for early access.
        </label>
        {formik.touched.terms && formik.errors.terms ? (
          <div className={styles.errorText}>{formik.errors.terms}</div>
        ) : null}
      </div>
      <CustomButton
        text={
          submitStatus.success === null
            ? "Join Whitelist"
            : submitStatus.message
        }
        type="submit"
        disabled={!(formik.isValid && formik.dirty)}
        style={{
          backgroundColor:
            submitStatus.success === true
              ? "#D3F1E2"
              : submitStatus.success === false
              ? "#FCCCCC"
              : "",
          color:
            submitStatus.success === true
              ? "#28793E"
              : submitStatus.success === false
              ? "#E20909"
              : "",
        }}
        icon={<PostIcon />}
      />
    </form>
  );
};
