import styles from "./styles.module.css";
import { useMemo, useRef, useState } from "react";
import useSwipeToScroll from "hooks/useSwipeToScroll";
import { CARD_DATA, ADVISORS } from "data/ourTeamData";
import TeamCard from "components/team-card";
import { CardButtons } from "components/cardButtons";
import { CustomTicker } from "components/custom-ticker";
import { useDeviceType } from "hooks";
import { CustomHeadline } from "components/ui";

export const Team = () => {
  const { device } = useDeviceType();

  const isDesktop = device === "desktop";

  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index

  const { containerRef, scrollLeftByStep, scrollRightByStep } =
    useSwipeToScroll();

  // Create a ref array for the cards
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const nextSlide = () => {
    if (currentIndex < CARD_DATA.length - 1) {
      setCurrentIndex(currentIndex + 1);
      scrollRightByStep();
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      scrollLeftByStep();
    }
  };

  const cards = useMemo(
    () =>
      CARD_DATA.map((card, index) => (
        <div
          className={styles.plansCardWrapper}
          key={index}
          ref={(el) => {
            cardRefs.current[index] = el;
          }} // Set the ref for each card
        >
          <TeamCard
            title={card.title}
            subtitle={card.subTitle}
            imageUrl={card.imgSrc}
            imageSize="small"
            description={card.description}
            borderColor={isDesktop ? "#FFFFFF" : "8dbfa4"}
          />
        </div>
      )),
    []
  );

  return (
    <>
      <div className={styles.container}>
        <CustomHeadline size={isDesktop ? 42 : 24} text="Builders of Impact" />
        <div className={styles.slider}>
          <div className={styles.cardsContainer} ref={containerRef}>
            {cards}
          </div>
          <div className={styles.cardBtns}>
            <CardButtons
              prevButtonProps={{
                onClick: prevSlide,
                style: { opacity: currentIndex === 0 ? 0.5 : 1 },
                disabled: currentIndex === 0,
              }}
              nextButtonProps={{
                onClick: nextSlide,
                style: {
                  opacity: currentIndex === CARD_DATA.length - 1 ? 0.5 : 1,
                },
                disabled: currentIndex === CARD_DATA.length - 1,
              }}
            />
          </div>
        </div>
      </div>

      <CustomTicker isDesktop={isDesktop} data={ADVISORS} title="Notable Advisors from:" />
    </>
  );
};
