import { SVGProps } from "react";

export const SuccessIcon = ({
  width = 21,
  height = 20,
  fill = "#28793E",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.5833 0 12.6083 0.158333 13.575 0.475C14.5417 0.791667 15.4333 1.23333 16.25 1.8L14.8 3.275C14.1667 2.875 13.4917 2.5625 12.775 2.3375C12.0583 2.1125 11.3 2 10.5 2C8.28333 2 6.39583 2.77917 4.8375 4.3375C3.27917 5.89583 2.5 7.78333 2.5 10C2.5 12.2167 3.27917 14.1042 4.8375 15.6625C6.39583 17.2208 8.28333 18 10.5 18C12.7167 18 14.6042 17.2208 16.1625 15.6625C17.7208 14.1042 18.5 12.2167 18.5 10C18.5 9.7 18.4833 9.4 18.45 9.1C18.4167 8.8 18.3667 8.50833 18.3 8.225L19.925 6.6C20.1083 7.13333 20.25 7.68333 20.35 8.25C20.45 8.81667 20.5 9.4 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM9.1 14.6L4.85 10.35L6.25 8.95L9.1 11.8L19.1 1.775L20.5 3.175L9.1 14.6Z"
      fill={fill}
    />
  </svg>
);
