import React from "react";
import styles from "./style.module.css";
import { ArrowRightIcon } from "assets/svg";

interface ButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
  disabled: boolean;
}

interface CardButtonsProps {
  prevButtonProps: ButtonProps;
  nextButtonProps: ButtonProps;
}

export const CardButtons: React.FC<CardButtonsProps> = ({
  prevButtonProps,
  nextButtonProps,
}) => {
  return (
    <div className={styles.buttonsContainer}>
      <button
        {...prevButtonProps}
        className={`${styles.sliderButton} ${styles.leftBtn}`}
      >
        <ArrowRightIcon />
        {""}
      </button>
      <button {...nextButtonProps} className={styles.sliderButton}>
        <ArrowRightIcon />
        {""}
      </button>
    </div>
  );
};
