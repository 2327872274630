import { SVGProps } from "react";

export const SelfTestingIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1084"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1084)">
      <path
        fill="#1C1B1F"
        d="M14.4 20 13 18.6l2.6-2.6-2.6-2.6 1.4-1.4 2.6 2.6 2.6-2.6 1.4 1.4-2.6 2.6 2.6 2.6-1.4 1.4-2.6-2.6zm1.975-9-3.55-3.55 1.4-1.4 2.125 2.126 4.25-4.25L22 5.35zM2 17v-2h9v2zm0-8V7h9v2z"
      ></path>
    </g>
  </svg>
);
