import { SVGProps } from "react";

export const EtherScanLogo = ({
  width = 30,
  height = 30,
  fill = "#28793E",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 41 40"
  >
    <g fill={fill} clipPath="url(#clip0_13467_1456)">
      <path d="M8.386 19.021c0-.923.758-1.681 1.68-1.681h2.833c.923 0 1.714.758 1.714 1.715v10.686q.495-.148 1.187-.297a1.446 1.446 0 0 0 1.088-1.386V14.8c0-.923.758-1.715 1.714-1.715h2.833c.923 0 1.714.758 1.714 1.715v12.304l1.384-.594c.528-.23.857-.724.857-1.32V10.513c0-.924.759-1.716 1.682-1.716h2.834c.923 0 1.681.759 1.681 1.716v12.105c2.439-1.78 4.944-3.925 6.922-6.497a2.78 2.78 0 0 0 .429-2.672C35.312 3.026 23.91-2.516 13.496 1.113S-2.454 16.153 1.17 26.577a18.6 18.6 0 0 0 1.548 3.396c.495.858 1.418 1.352 2.407 1.253a37 37 0 0 0 2.01-.198c.724-.066 1.252-.693 1.252-1.42zM8.32 36.173c8.932 6.498 21.423 4.518 27.914-4.42a20.17 20.17 0 0 0 3.822-11.774c0-.462-.033-.923-.065-1.385C32.709 29.51 19.229 34.623 8.32 36.173"></path>
    </g>
    <defs>
      <clipPath id="clip0_13467_1456">
        <path fill="#fff" d="M.055 0h40v40h-40z"></path>
      </clipPath>
    </defs>
  </svg>
);
