import { SVGProps } from "react";

export const BitcoinIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13476_1684"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13476_1684)">
      <path
        fill="#1C1B1F"
        d="M9 21v-2H6v-2h2V7H6V5h3V3h2v2h2V3h2v2.125a4.03 4.03 0 0 1 2.15 1.412Q18 7.6 18 9q0 .725-.25 1.387-.25.663-.7 1.188.875.525 1.412 1.425.538.9.538 2 0 1.65-1.175 2.825T15 19v2h-2v-2h-2v2zm1-10h4q.825 0 1.412-.588Q16 9.826 16 9t-.588-1.412A1.93 1.93 0 0 0 14 7h-4zm0 6h5q.824 0 1.413-.587Q17 15.825 17 15t-.587-1.412A1.93 1.93 0 0 0 15 13h-5z"
      ></path>
    </g>
  </svg>
);
