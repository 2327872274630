import { SVGProps } from "react";

export const CardsStarIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13496_718"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13496_718)">
      <path
        fill="#1C1B1F"
        d="m12.226 12.5 2.275-1.375 2.275 1.375-.6-2.6 2-1.725-2.625-.225-1.05-2.45-1.05 2.45-2.625.225 2 1.725zm.475 6.5h5.6q-.175.65-.6 1.05t-1.1.5l-10.9 1.325a1.88 1.88 0 0 1-1.487-.387 1.92 1.92 0 0 1-.763-1.338L2.126 9.225a1.95 1.95 0 0 1 .4-1.475A1.9 1.9 0 0 1 3.851 7l1.15-.15v2l-.9.125L5.451 19.9zm-3.7-2q-.825 0-1.412-.587A1.93 1.93 0 0 1 7 15V4q0-.824.588-1.412A1.93 1.93 0 0 1 9 2h11q.825 0 1.412.587.588.588.588 1.413v11q0 .824-.588 1.413a1.93 1.93 0 0 1-1.412.587zm0-2h11V4h-11z"
      ></path>
    </g>
  </svg>
);
