import styles from "./styles.module.css";
import { ContactForm } from "components";
import { PARTNERS } from "data/getInTouchData";
import { CustomTicker } from "components/custom-ticker";
import { useDeviceType } from "hooks";
import { CustomHeadline, CustomText } from "components/ui";

export const GetInTouch = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <CustomHeadline size={isDesktop ? 42 : 24} text="Let's Talk" />
          <CustomText
            size={isDesktop ? 20 : 16}
            text="Have something big to offer? Partnerships, contributions, or
            ideas—fill out the form."
          />
          <ContactForm />
        </div>
      </div>

      <CustomTicker
        isDesktop={isDesktop}
        data={PARTNERS}
        title="Our partners:"
      />
    </>
  );
};
