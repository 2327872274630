import { Socials } from "components/socials";
import styles from "./styles.module.css";
import { TEAM_LINKEDIN } from "data/ourTeamLinkedIn";
import PartnerLinkedIn from "./parnter-linkedin";
import { ContactForm } from "components/form";
import { useDeviceType } from "hooks";

export const Colaborations: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  if (isDesktop) {
    return (
      <div className={styles.desktopContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.touchTitle}>Let's Talk</h2>
          <p className={styles.touchPrgrph}>
            Have something big to offer? Partnerships, contributions, or
            ideas—fill out the form.
          </p>
          <div className={styles.contactFormContainer}>
            <ContactForm />
          </div>
        </div>
        <div className={styles.colabContainer}>
          <div className={styles.socContainer}>
            <h3 className={styles.connectWith}>
              Prefer email? Reach us at <br />
              <a
                className={styles.email}
                href="mailto:info@mindscriber.xyz"
                target="_blank"
                rel="noreferrer"
              >
                Info@MindScriber.xyz
              </a>
            </h3>
            <div className={styles.icons}>
              <Socials />
            </div>
            <p className={styles.privacy}>Privacy Policy</p>
            <div className={styles.members}>
              {TEAM_LINKEDIN.map((member, index) => {
                return <PartnerLinkedIn key={index} member={member} />;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.socContainer}>
        <h3 className={styles.connectWith}>
          Prefer email? Reach us at <br />
          <a
            className={styles.email}
            href="mailto:info@mindscriber.xyz"
            target="_blank"
            rel="noreferrer"
          >
            Info@MindScriber.xyz
          </a>
        </h3>
        <div className={styles.icons}>
          <Socials />
        </div>
        <p className={styles.privacy}>Privacy Policy</p>
      </div>
      <div className={styles.members}>
        {TEAM_LINKEDIN.map((member, index) => {
          return <PartnerLinkedIn key={index} member={member} />;
        })}
      </div>
    </div>
  );
};
