import { useState } from "react";
import styles from "./styles.module.css";
import { TokenDistribution } from "./token-distribution";
import { Funds } from "./funds";
import { PdfIcon } from "assets/svg";
import { useDeviceType } from "hooks";
import { CustomHeadline, CustomText } from "components/ui";

export const Tokenomiks = () => {
  const [activeTab, setActiveTab] = useState<"token" | "funds">("token");

  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  if (isDesktop) {
    return (
      <div className={styles.desktopContainer}>
        <div className={styles.desktopHeader}>
          <CustomHeadline
            styles={{ width: "45dvw" }}
            size={42}
            text="Empowering Growth With $SCRB"
          />

          <div className={styles.netWorkContainer}>
            <CustomText
              styles={{ width: "45dvw" }}
              size={20}
              text="Network: Built on Ethereum for secure, scalable solutions."
            />
            <button
              className={styles.Pdfbtn}
              onClick={() =>
                window.open(
                  "https://docsend.com/v/j7773/scriber_light_paper",
                  "_blank"
                )
              }
            >
              <PdfIcon height={20} width={20} />
              Light Paper
            </button>
          </div>
        </div>
        <div className={styles.desktopContent}>
          <TokenDistribution />
          <Funds />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>Empowering Growth With $SCRB</h2>
        <p className={styles.prgrph}>
          <b>Network:</b> Built on Ethereum for secure, scalable solutions.
        </p>
      </div>
      <div className={styles.buttons}>
        <button
          onClick={() => setActiveTab("token")}
          className={`${styles.btn} ${
            activeTab === "token" ? styles.activeBtn : ""
          }`}
        >
          Token Distribution
        </button>
        <button
          onClick={() => setActiveTab("funds")}
          className={`${styles.btn} ${
            activeTab === "funds" ? styles.activeBtn : ""
          }`}
        >
          Funds Allocation
        </button>
      </div>
      {activeTab === "token" && <TokenDistribution />}
      {activeTab === "funds" && <Funds />}
      <button
        className={styles.Pdfbtn}
        onClick={() =>
          window.open(
            "https://docsend.com/v/j7773/scriber_light_paper",
            "_blank"
          )
        }
      >
        <PdfIcon />
        Light Paper
      </button>
    </div>
  );
};
