import { SVGProps } from "react";

export const BlockLines = ({
  width = 3,
  height = 300,
  fill = "#DCF2E7",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 3 429"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 0V429" stroke={fill} strokeWidth="2" />
  </svg>
);
