import { SVGProps } from "react";

export const NetworkIntelIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13476_1731"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13476_1731)">
      <path
        fill="#1C1B1F"
        d="M8.075 20q-.275 0-.512-.137a.9.9 0 0 1-.363-.388L5.25 16H6.7l1 2H10v-1H8.3l-1-2H4.7l-1.425-2.5a3 3 0 0 1-.087-.25.9.9 0 0 1-.038-.25q0-.1.125-.5L4.7 9h2.6l1-2H10V6H7.7l-1 2H5.25L7.2 4.525a.9.9 0 0 1 .363-.388Q7.8 4 8.075 4H10.5q.426 0 .713.287.288.288.287.713v4H10l-1 1h2.5v3H9.3l-1-2H6l-1 1h2.7l1 2h2.8v5q0 .424-.287.712A.97.97 0 0 1 10.5 20zM14 20q-.825 0-1.412-.587A1.93 1.93 0 0 1 12 18q0-.574.275-1.012t.725-.713v-8.55q-.45-.274-.725-.712A1.86 1.86 0 0 1 12 6q0-.824.588-1.412A1.93 1.93 0 0 1 14 4q.825 0 1.413.588Q16 5.175 16 6q0 .574-.275 1.013-.275.437-.725.712v2.525l2-1.2q0-.85.588-1.45Q18.175 7 19 7t1.413.588T21 9t-.587 1.412A1.93 1.93 0 0 1 19 11q-.275 0-.512-.062a2 2 0 0 1-.463-.188l-2.275 1.375 2.525 2q.176-.075.35-.1A3 3 0 0 1 19 14q.825 0 1.413.588Q21 15.175 21 16q0 .824-.587 1.413A1.93 1.93 0 0 1 19 18q-.924 0-1.512-.7t-.463-1.6L15 14.075V16.3q.45.274.713.712t.262.988q0 .824-.575 1.413Q14.825 20 14 20"
      ></path>
    </g>
  </svg>
);
