import {
  BedTimeIcon,
  FitnesTrackerIcon,
  MemoryIcon,
  MisteryIcon,
  ScanIcon,
  SelfTestingIcon,
  SpeedIcon,
  TextToImageIcon,
  VoicesCloneIcon,
} from "assets/svg";
import Slide1Img from "assets/images/we-offer1.png";
import Slide2Img from "assets/images/we-offer2.png";
import Slide3Img from "assets/images/we-offer3.png";
import Slide4Img from "assets/images/we-offer4.png";
import Slide5Img from "assets/images/we-offer5.png";
import Slide6Img from "assets/images/we-offer6.png";
import Slide7Img from "assets/images/we-offer7.png";
import Slide8Img from "assets/images/we-offer8.png";
import Slide9Img from "assets/images/we-offer9.png";

export const CARD_DATA = [
  {
    titleIcon: BedTimeIcon,
    title: "Night Mode: Healthy Learning™",
    subTitle: "Unlock your potential—even in dreams!",
    imgSrc: Slide1Img,
  },
  {
    titleIcon: MemoryIcon,
    title: "Lecture AI Summary",
    subTitle:
      "Effortlessly absorb what matters most—leaving you more time to explore & excel.",
    imgSrc: Slide2Img,
  },
  {
    titleIcon: SelfTestingIcon,
    title: "Full Self-Testing",
    subTitle: "Self-testing has never been this fun—or this effective!",
    imgSrc: Slide3Img,
  },
  {
    titleIcon: TextToImageIcon,
    title: "Text to image",
    subTitle:
      "Listen as text comes alive with vivid, real-time visualizations.",
    imgSrc: Slide4Img,
  },
  {
    titleIcon: VoicesCloneIcon,
    title: "Voice Clones",
    subTitle:
      "Now, every word feels personal and familiar, making learning truly enjoyable.",
    imgSrc: Slide5Img,
  },
  {
    titleIcon: MisteryIcon,
    title: "Seamless tracking",
    subTitle:
      "Real-time text highlighting keeps you focused and on track as you listen.",
    imgSrc: Slide6Img,
  },
  {
    titleIcon: SpeedIcon,
    title: "Set Your Pace",
    subTitle: "Adjust the reading speed to perfectly match your comprehension.",
    imgSrc: Slide7Img,
  },
  {
    titleIcon: FitnesTrackerIcon,
    title: "Health Synclify",
    subTitle:
      "Sync with your smartwatch to monitor your mood, health, and readiness for learning.",
    imgSrc: Slide8Img,
  },
  {
    titleIcon: ScanIcon,
    title: "Scan & listen",
    subTitle:
      "Scan text with your camera, convert it to digital, and let the app read it aloud.",
    imgSrc: Slide9Img,
  },
];
