import React from "react";
import styles from "./styles.module.css";

interface IProps {
  data: string[];
  title?: string;
  isDesktop?: boolean
}

export const CustomTicker = ({ data, title, isDesktop }: IProps) => {
  const combinedData = [...data, ...data, ...data, ...data]; // Duplicate the data for seamless looping


  return (
    <div className={styles.container}>
      {title && <h4 className={styles.title}>{title}</h4>}
      <div className={styles.tickerWrapper}>
        <div className={isDesktop ? styles.tickerContentDesktop :  styles.tickerContent}>
          {(isDesktop ? data : combinedData).map((icon, index) => (
            <img
              key={index}
              src={icon}
              alt={`ticker-icon-${index}`}
              className={styles.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
