import { SVGProps } from "react";

export const PdfIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_938"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_938)">
      <path
        fill="#28793E"
        d="M9 12.5h1v-2h1q.424 0 .713-.287A.97.97 0 0 0 12 9.5v-1a.97.97 0 0 0-.287-.713A.97.97 0 0 0 11 7.5H9zm1-3v-1h1v1zm3 3h2q.424 0 .713-.287A.97.97 0 0 0 16 11.5v-3a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 7.5h-2zm1-1v-3h1v3zm3 1h1v-2h1v-1h-1v-1h1v-1h-2zM8 18q-.824 0-1.412-.587A1.93 1.93 0 0 1 6 16V4q0-.824.588-1.412A1.93 1.93 0 0 1 8 2h12q.824 0 1.413.587Q22 3.176 22 4v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 18zm0-2h12V4H8zm-4 6q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 20V6h2v14h14v2z"
      ></path>
    </g>
  </svg>
);
