import styles from "./styles.module.css";
import { ICONS_DATA } from "data/ourStoryIconsData";
import igarImg from "assets/images/igar.png";
import { CustomTicker } from "components/custom-ticker";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";

export const OurStory: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <CustomHeadline
            size={isDesktop ? 42 : 24}
            text="The Vision That Changed Everything"
          />
          <CustomText
            size={isDesktop ? 20 : 16}
            text="Igar Dyachenko turned his dyslexia into a mission to empower the 10%
            of the world who are dyslexic and the 20% who are neurodivergent."
          />
          <CustomText
            size={isDesktop ? 20 : 16}
            text=" With a team of leading scientists, he built a platform transforming
            learning through neurotechnology and AI—unlocking 8 hours of
            untapped potential to create a $1 trillion hybrid market."
          />
        </div>
        <img className={styles.image} src={igarImg} alt="igarImg" />
      </div>
      <CustomTicker isDesktop={isDesktop} data={ICONS_DATA} />
    </>
  );
};
