import styles from "./styles.module.css";
import { useMemo, useRef, useState } from "react";
import OmerCard from "components/card";
import useSwipeToScroll from "hooks/useSwipeToScroll";
import { CARD_DATA } from "data/weOfferCardData";
import { CardButtons } from "components/cardButtons";
import { CustomHeadline, CustomText } from "components/ui";
import { useDeviceType } from "hooks";

export const WeOffer: React.FC = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index

  const { containerRef, scrollLeftByStep, scrollRightByStep } =
    useSwipeToScroll();

  // Create a ref array for the cards
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const nextSlide = () => {
    if (currentIndex < CARD_DATA.length - 1) {
      setCurrentIndex(currentIndex + 1);
      scrollRightByStep();
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      scrollLeftByStep();
    }
  };

  const cards = useMemo(
    () =>
      CARD_DATA.map((card, index) => (
        <div
          className={styles.plansCardWrapper}
          key={index}
          ref={(el) => {
            cardRefs.current[index] = el;
          }} // Set the ref for each card
        >
          <OmerCard
            title={card.title}
            subtitle={card.subTitle}
            imageUrl={card.imgSrc}
            imageSize="large"
            IconComponent={card.titleIcon}
          />
        </div>
      )),
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CustomHeadline size={isDesktop ? 42 : 24} text="Tools That Fit You" />
        <CustomText
          size={isDesktop ? 20 : 16}
          text="Experience seamless learning with advanced voice tech and device
          integration, making it simpler and efficient."
        />
        <div className={styles.slider}>
          <div className={styles.cardsContainer} ref={containerRef}>
            {cards}
          </div>
          <div className={styles.cardBtns}>
            <CardButtons
              prevButtonProps={{
                onClick: prevSlide,
                style: { opacity: currentIndex === 0 ? 0.5 : 1 },
                disabled: currentIndex === 0,
              }}
              nextButtonProps={{
                onClick: nextSlide,
                style: {
                  opacity: currentIndex === CARD_DATA.length - 1 ? 0.5 : 1,
                },
                disabled: currentIndex === CARD_DATA.length - 1,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
