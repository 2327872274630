import { SVGProps } from "react";

export const VoicesCloneIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
     <mask
      id="mask0_13436_1104"
      width="24"
      height="25"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 .156h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1104)">
      <path
        fill="#1C1B1F"
        d="M2 22.157v-2q1.15 0 2.275-.15t2.2-.55a4.37 4.37 0 0 1-1.812-1.663A4.46 4.46 0 0 1 4 15.433v-2.275h4v-3h3.375L8.1 3.607l1.8-.9 3.275 6.55q.5 1-.075 1.95t-1.7.95H10v1q0 .825-.588 1.412A1.93 1.93 0 0 1 8 15.157H6v.275q0 .875.537 1.537a2.6 2.6 0 0 0 1.363.888l.3.075q1 .25 1.125 1.25t-.775 1.5q-1.5.825-3.163 1.15A17.6 17.6 0 0 1 2 22.157m14.3-2.85-1.425-1.4q.525-.525.825-1.212.3-.689.3-1.488 0-.8-.3-1.488a3.9 3.9 0 0 0-.825-1.212l1.425-1.425a5.81 5.81 0 0 1 1.7 4.125q0 1.2-.45 2.25a5.9 5.9 0 0 1-1.25 1.85m2.825 2.85L17.7 20.732a8 8 0 0 0 1.688-2.488A7.55 7.55 0 0 0 20 15.208q0-1.65-.613-3.05A8 8 0 0 0 17.7 9.682l1.425-1.425q1.35 1.35 2.113 3.125T22 15.207q0 2.024-.762 3.813a9.9 9.9 0 0 1-2.113 3.137"
      ></path>
    </g>
  </svg>
);
