import { SVGProps } from "react";

export const PokerIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13476_1676"
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 0h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13476_1676)">
      <path
        fill="#1C1B1F"
        d="M12 22a9.7 9.7 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137q-1.35-1.35-2.137-3.175A9.7 9.7 0 0 1 2 12q0-2.075.788-3.9a10.1 10.1 0 0 1 2.137-3.175q1.35-1.35 3.175-2.137A9.7 9.7 0 0 1 12 2q2.075 0 3.9.788a10.1 10.1 0 0 1 3.175 2.137q1.35 1.35 2.137 3.175A9.7 9.7 0 0 1 22 12a9.7 9.7 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175q-1.35 1.35-3.175 2.137A9.7 9.7 0 0 1 12 22m-1-2.075v-1a6.6 6.6 0 0 1-1.687-.475A8 8 0 0 1 7.8 17.6l-.7.725q.825.65 1.813 1.063a7.6 7.6 0 0 0 2.087.537m2 0a7.6 7.6 0 0 0 2.088-.538 7.7 7.7 0 0 0 1.812-1.062l-.7-.725a8 8 0 0 1-1.512.85 6.6 6.6 0 0 1-1.688.475zM12 17q2.075 0 3.537-1.463Q17 14.075 17 12q0-2.075-1.463-3.537Q14.075 7 12 7 9.925 7 8.463 8.463 7 9.926 7 12q0 2.075 1.463 3.537Q9.926 17 12 17m6.325-.1a7.7 7.7 0 0 0 1.063-1.812A7.6 7.6 0 0 0 19.925 13h-1a6.6 6.6 0 0 1-.475 1.688 8 8 0 0 1-.85 1.512zm-12.65 0 .725-.725a8.3 8.3 0 0 1-.85-1.5A6.4 6.4 0 0 1 5.075 13h-1q.125 1.1.537 2.088.414.987 1.063 1.812M12 16l-3-4 3-4 3 4zm-7.925-5h1a6.4 6.4 0 0 1 .475-1.675 8.3 8.3 0 0 1 .85-1.5L5.675 7.1a7.7 7.7 0 0 0-1.062 1.813A7.6 7.6 0 0 0 4.074 11m14.85 0h1a7.4 7.4 0 0 0-.55-2.088A8 8 0 0 0 18.3 7.1l-.7.7q.5.7.85 1.513A6.6 6.6 0 0 1 18.925 11m-11.1-4.6a8.3 8.3 0 0 1 1.5-.85A6.4 6.4 0 0 1 11 5.075v-1a7.6 7.6 0 0 0-2.088.537A7.7 7.7 0 0 0 7.1 5.676zm8.375 0 .7-.7a8 8 0 0 0-1.812-1.075A7.4 7.4 0 0 0 13 4.075v1a6.6 6.6 0 0 1 1.688.475 8 8 0 0 1 1.512.85"
      ></path>
    </g>
  </svg>
);
