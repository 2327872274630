import { CSSProperties, ReactNode } from "react";

import s from "./styles.module.css";
import { calculateResponsiveFontSize } from "utils";

interface IProps {
  text: string | ReactNode;
  size?: number;
  color?: string;
  styles?: CSSProperties;
}

export const CustomText = ({
  text,
  size = 16,
  color = "#3F3F3F",
  styles,
}: IProps) => {
  const fontSize = calculateResponsiveFontSize(size);
  const lineHeight = `${(size * 4) / 3}px`;

  return (
    <p style={{ ...styles, fontSize, lineHeight, color }} className={s.prgrph}>
      {text}
    </p>
  );
};
