import { SVGProps } from "react";

export const MisteryIcon = ({
  width = 24,
  height = 24,
  fill = "#D9D9D9",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <mask
      id="mask0_13436_1115"
      width="24"
      height="25"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill={fill} d="M0 .156h24v24H0z"></path>
    </mask>
    <g mask="url(#mask0_13436_1115)">
      <path
        fill="#1C1B1F"
        d="M11 18.156q2.9 0 4.95-2.037T18 11.156q0-2.9-2.05-4.95T11 4.156q-2.925 0-4.962 2.05T4 11.156q0 2.925 2.037 4.963Q8.076 18.156 11 18.156m0-3q-2.075 0-3.687-1.112Q5.7 12.932 5 11.156q.7-1.75 2.313-2.875Q8.925 7.156 11 7.156q2.05 0 3.662 1.125Q16.276 9.406 17 11.156a6.37 6.37 0 0 1-2.338 2.888Q13.05 15.156 11 15.156m0-1.5a4.97 4.97 0 0 0 2.525-.662 4.7 4.7 0 0 0 1.8-1.838 4.85 4.85 0 0 0-1.8-1.825A4.9 4.9 0 0 0 11 8.656q-1.4 0-2.55.675a4.85 4.85 0 0 0-1.8 1.825 4.7 4.7 0 0 0 1.8 1.838 5 5 0 0 0 2.55.662m0-1q.624 0 1.063-.425.437-.424.437-1.075 0-.624-.437-1.062A1.45 1.45 0 0 0 11 9.656q-.65 0-1.075.438a1.47 1.47 0 0 0-.425 1.062q0 .65.425 1.075.425.426 1.075.425m0 7.5q-1.874 0-3.512-.712a9.2 9.2 0 0 1-2.85-1.925 9.2 9.2 0 0 1-1.925-2.85A8.7 8.7 0 0 1 2 11.156q0-1.85.712-3.487a9.3 9.3 0 0 1 1.926-2.863 9 9 0 0 1 2.85-1.937A8.7 8.7 0 0 1 11 2.156q1.85 0 3.488.713a9.2 9.2 0 0 1 2.862 1.937 9.2 9.2 0 0 1 1.938 2.863A8.65 8.65 0 0 1 20 11.156a8.7 8.7 0 0 1-.525 3.025 9.5 9.5 0 0 1-1.45 2.6L22 20.756l-1.425 1.4-3.975-3.95a9.2 9.2 0 0 1-2.6 1.438q-1.425.512-3 .512"
      ></path>
    </g>
  </svg>
);
