import React from "react";
import styles from "./styles.module.css";

// Custom Input Component
interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: string;
  touched?: boolean;
}

export const CustomInput = ({
  name,
  error,
  touched,
  ...restProps
}: CustomInputProps) => (
  <div className={styles.formControl}>
    <input
      id={name}
      name={name}
      className={`${styles.input} ${error && touched ? styles.error : ""}`}
      {...restProps}
    />
    {error && touched && <div className={styles.errorText}>{error}</div>}
  </div>
);
