export const calculateResponsiveFontSize = (fontSizePx: number): string => {
  const isPortrait = window.innerHeight > window.innerWidth;

  const aspectRatio = window.innerHeight / window.innerWidth;

  const baseSize = isPortrait
    ? fontSizePx / (window.innerWidth / 100)
    : fontSizePx / (window.innerHeight / 100);

  const responsiveSize =
    baseSize * (isPortrait ? 1 / aspectRatio : aspectRatio);

  return `${baseSize}${isPortrait ? "dvw" : "dvh"}`;
};
