import styles from "./styles.module.css";

interface IProps {
  member: {
    imgSrc: any;
    title: string;
    subTitle: string;
    link: string;
  };
}

const PartnerLinkedIn = ({ member }: IProps) => {
  return (
    <a
      className={styles.cardContainer}
      href={member.link}
      target="_blank"
      rel="noreferrer"
    >
      <img className={styles.img} src={member?.imgSrc} alt="Slider" />
      <div className={styles.info}>
        <h2 className={styles.title}>{member.title}</h2>
        <h2 className={styles.subTitle}>{member.subTitle}</h2>
      </div>
    </a>
  );
};

export default PartnerLinkedIn;
