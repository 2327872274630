import { useDeviceType } from "hooks";
import styles from "./styles.module.css";
import chartImg from "assets/images/funds-chart.png";
import { CustomText } from "components/ui";

export const Funds = () => {
  const { device } = useDeviceType();
  const isDesktop = device === "desktop";

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <ul className={styles.infoList}>
          <li className={styles.infoListItem}>
            <CustomText
              size={isDesktop ? 20 : 16}
              text="Enhance AI and blockchain systems."
            />
          </li>
          <li className={styles.infoListItem}>
            <CustomText
              size={isDesktop ? 20 : 16}
              text="Scale global user acquisition campaigns."
            />
          </li>
          <li className={styles.infoListItem}>
            <CustomText
              size={isDesktop ? 20 : 16}
              text="Expand the NFT and staking ecosystem."
            />
          </li>
        </ul>
        <div className={styles.imgContainer}>
          <img src={chartImg} alt="chart" className={styles.chartImg} />
        </div>
      </div>
    </div>
  );
};
