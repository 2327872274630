import {
  EmailIcon,
  InstagramIcon,
  LineIcon,
  TelegramIcon,
  XIcon,
} from "assets/svg";
import styles from "./styles.module.css";

export const ModalSocials = () => {
  return (
    <div className={styles.socials}>
      <a href="https://t.me/mindscriber" target="_blank" rel="noreferrer">
        <TelegramIcon />
      </a>
      <a
        href="https://www.instagram.com/scriber_scrb/"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>
      <a href="https://x.com/MindScriber" target="_blank" rel="noreferrer">
        <XIcon />
      </a>
      <div>
        <LineIcon />
      </div>
      <a href="mailto:info@mindscriber.xyz" target="_blank" rel="noreferrer">
        <EmailIcon />
      </a>
    </div>
  );
};
