export enum NavId {
  OurMission = "our-mission",
  OurStory = "our-story",
  Challenge = "challenge",
  TechFramework = "tech-framework",
  Tokenomics = "tokenomics",
  WhatWeOffer = "what-we-offer",
  Team = "team",
  Journey = "journey-ahead",
  GetInTouch = "get-in-touch",
  Collaborations = "collaborations",
}
export enum NavLable {
  OurMission = "Our Mission",
  OurStory = "Our Story",
  Challenge = "The Challenge",
  TechFramework = "Tech Framework",
  Tokenomics = "Tokenomics",
  WhatWeOffer = "What We Offer",
  Team = "Our Team & Allies",
  Journey = "The Journey Ahead",
  GetInTouch = "Get in Touch",
  Collaborations = "Collaborations",
}

export interface ILink {
  id: string;
  value: string;
}
