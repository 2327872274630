import { ILink, NavId, NavLable } from "types/navigation";

export const LINKS: ILink[] = [
  {
    id: NavId.OurMission,
    value: NavLable.OurMission,
  },
  {
    id: NavId.OurStory,
    value: NavLable.OurStory,
  },
  {
    id: NavId.Challenge,
    value: NavLable.Challenge,
  },
  {
    id: NavId.TechFramework,
    value: NavLable.TechFramework,
  },
  {
    id: NavId.Tokenomics,
    value: NavLable.Tokenomics,
  },
  {
    id: NavId.WhatWeOffer,
    value: NavLable.WhatWeOffer,
  },
  {
    id: NavId.Journey,
    value: NavLable.Journey,
  },

  {
    id: NavId.Team,
    value: NavLable.Team,
  },

  {
    id: NavId.GetInTouch,
    value: NavLable.GetInTouch,
  },
  {
    id: NavId.Collaborations,
    value: NavLable.Collaborations,
  },
];

export const LINKS_DESKTOP: ILink[] = [
  {
    id: NavId.OurMission,
    value: NavLable.OurMission,
  },
  {
    id: NavId.OurStory,
    value: NavLable.OurStory,
  },
  {
    id: NavId.Challenge,
    value: NavLable.Challenge,
  },
  {
    id: NavId.TechFramework,
    value: NavLable.TechFramework,
  },
  {
    id: NavId.Tokenomics,
    value: NavLable.Tokenomics,
  },
  {
    id: NavId.WhatWeOffer,
    value: NavLable.WhatWeOffer,
  },
  {
    id: NavId.Journey,
    value: NavLable.Journey,
  },

  {
    id: NavId.Team,
    value: NavLable.Team,
  },

  {
    id: NavId.GetInTouch,
    value: NavLable.GetInTouch,
  },
];