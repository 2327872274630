import PartnersImg1 from "assets/images/enjinstarter.png";
import PartnersImg2 from "assets/images/fetchai.png";
import PartnersImg3 from "assets/images/coinmarketcap.png";
import PartnersImg4 from "assets/images/gempad.png";
import PartnersImg5 from "assets/images/speechmatics.png";
import PartnersImg6 from "assets/images/layer-zero.png";

export const PARTNERS: Array<any> = [
  PartnersImg1,
  PartnersImg2,
  PartnersImg3,
  PartnersImg4,
  PartnersImg5,
  PartnersImg6,
];
