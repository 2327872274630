import { SVGProps } from "react";

export const CoinMCIcon = ({
  width = 30,
  height = 30,
  fill = "#28793E",
  style,
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 41 40"
  >
    <g clipPath="url(#clip0_13467_1448)">
      <path
        fill={fill}
        d="M40.05 19.79v11.05c0 1.115-.082 2.219-.311 3.317-.356 1.705-1.295 3.038-2.618 4.131-1.065.88-2.3 1.355-3.661 1.53-.956.12-1.924.18-2.89.175q-10.763.002-21.525-.006a15.4 15.4 0 0 1-3.17-.311c-1.218-.257-2.262-.83-3.18-1.656-.978-.88-1.727-1.918-2.131-3.186-.23-.726-.345-1.47-.405-2.224-.098-1.18-.093-2.36-.093-3.54Q.065 18.987.072 8.906c0-1.246.115-2.487.464-3.689.252-.858.7-1.607 1.257-2.295C2.63 1.895 3.64 1.102 4.881.616 5.586.343 6.323.206 7.07.13 8.149.015 9.226.004 10.302.004h20.235c.782 0 1.563.005 2.34.093 1.475.17 2.879.54 4.081 1.459 1.46 1.115 2.42 2.563 2.798 4.377.197.945.295 1.912.295 2.874z"
      ></path>
      <path
        fill="#FEFEFE"
        d="M24.601 18.34c-.218.339-.437.683-.65 1.022-.814 1.284-1.596 2.585-2.388 3.88-.252.41-.552.781-.945 1.07-1.203.886-2.443-.01-2.585-1.06a16 16 0 0 1-.17-2.043c-.005-.792 0-1.585 0-2.372 0-.114-.01-.229-.016-.344-.055 0-.06.044-.077.066-1.497 2.475-3.07 4.907-4.6 7.36-.094.148-.18.301-.28.449-.043.065-.043.109.017.174 1.547 1.601 3.383 2.683 5.601 3.022 2.694.416 5.142-.191 7.334-1.82.267-.196.508-.426.77-.633.322-.252.678-.405 1.104-.306q.09.017.153.076c.098.099.197.186.306.273.328.24.344.563.257.919-.11.42-.4.71-.722.972-1.486 1.235-3.158 2.115-5.06 2.514-3.64.77-6.956.038-9.88-2.262-2.229-1.755-3.644-4.05-4.267-6.826a11.8 11.8 0 0 1-.137-4.35c.667-4.535 3.902-7.972 7.525-9.35a11.1 11.1 0 0 1 3.65-.731c1.366-.044 2.705.076 4.016.497a11.8 11.8 0 0 1 4.662 2.776q2.728 2.641 3.448 6.371c.235 1.214.29 2.438.093 3.656-.235 1.47-1.427 2.93-3.039 3.235-1.595.306-3.082-.519-3.727-2.076-.278-.672-.355-1.377-.355-2.093v-1.978q.001-.033-.005-.066z"
      ></path>
      <path
        fill={fill}
        d="M20.023 21.525v-.99c0-1.36.005-2.726-.011-4.087-.006-.552-.132-1.076-.536-1.492a1.27 1.27 0 0 0-1.393-.268 1.87 1.87 0 0 0-.81.678c-.48.683-.884 1.415-1.338 2.115-1.464 2.235-2.83 4.53-4.273 6.776-.115.18-.213.186-.312-.017a9 9 0 0 1-.322-.737 9.4 9.4 0 0 1-.656-3.328c-.033-2.383.634-4.541 2.137-6.399 1.475-1.825 3.371-3.011 5.688-3.437 3.35-.612 6.257.322 8.678 2.688 1.508 1.47 2.383 3.295 2.732 5.377.131.765.159 1.547.082 2.317-.06.569-.29 1.066-.743 1.432-.377.3-.803.47-1.279.279-.502-.197-.76-.607-.847-1.131-.125-.765-.109-1.536-.093-2.306.011-.656.055-1.323-.087-1.973-.17-.754-.607-1.29-1.388-1.486-.377-.093-.727.032-1.055.207-.497.268-.874.672-1.19 1.126-.57.803-1.061 1.661-1.586 2.497l-1.311 2.099c-.016.021-.033.049-.05.07z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_13467_1448">
        <path fill="#fff" d="M.055 0h40v40h-40z"></path>
      </clipPath>
    </defs>
  </svg>
);
