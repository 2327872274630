import {
  AutoStoriesIcon,
  BitcoinIcon,
  CardsStarIcon,
  NetworkIntelIcon,
  PokerIcon,
  PowerIcon,
} from "assets/svg";
import SliderImg1 from "assets/images/stack-1.png";
import SliderImg2 from "assets/images/stack-2.png";
import SliderImg3 from "assets/images/stack-3.png";
import SliderImg4 from "assets/images/stack-4.png";
import SliderImg5 from "assets/images/stack-5.png";
import SliderImg6 from "assets/images/stack-6.png";

export const CARD_DATA = [
  {
    titleIcon: AutoStoriesIcon,
    title: "AI PERSONALIZED LEARNING",
    subTitle:
      "Our proprietary Language Neural Network (LNN) tailors learning experiences for individual needs, optimizing both day and night sessions for maximum retention.",
    imgSrc: SliderImg1,
  },
  {
    titleIcon: PokerIcon,
    title: "TOKENIZED REWARD SYSTEM",
    subTitle:
      "Earn $SCRB tokens through learning, self-testing, and engagement. With Sleep-n-Learn-to-Earn, users can gain rewards while enhancing memory retention during sleep.",
    imgSrc: SliderImg2,
  },
  {
    titleIcon: CardsStarIcon,
    title: "FRACTIONALIZED OWNERSHIP",
    subTitle:
      "Empowers creators and learners to share course ownership, enabling monetization and revenue sharing through blockchain technology.",
    imgSrc: SliderImg3,
  },
  {
    titleIcon: PowerIcon,
    title: "API FOR INSTITUTIONS:",
    subTitle:
      "Integrates gamification, token rewards, and data-sharing tools seamlessly into institutional platforms to enhance learning outcomes.",
    imgSrc: SliderImg4,
  },
  {
    titleIcon: BitcoinIcon,
    title: "DECENTRALIZED MARKETPLACE",
    subTitle:
      "A secure platform for creators to monetize educational content through peer-to-peer transactions and tokenized tools.",
    imgSrc: SliderImg5,
  },
  {
    titleIcon: NetworkIntelIcon,
    title: "LANGUAGE NEUTRAL NETWORK (LNN)",
    subTitle:
      "Facilitates multilingual learning and provides anonymized insights to enhance educational offerings globally.",
    imgSrc: SliderImg6,
  },
];
