import { SVGProps } from "react";

export const ArrowRightIcon = ({
  width = 11,
  height = 18,
  fill = "#1C1B1F",
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#ECF6F0" />
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#28793E" />
    <path
      d="M15.8919 26L24 18L15.8919 10L14 11.8667L20.2162 18L14 24.1333L15.8919 26Z"
      fill="#28793E"
    />
  </svg>
);
