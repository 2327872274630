import { useEffect, useRef, useState } from "react";

const useSwipeToScroll = () => {
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isMoving, setIsMoving] = useState(false); // Track if the user is swiping
  const containerRef = useRef<HTMLDivElement | null>(null);

  const threshold = 1; // Use a very small threshold for sensitive movement
  const sensitivity = 2; // Increase sensitivity, adjust this value as needed (e.g., 1, 2, 3)

  const handleTouchStart = (e: TouchEvent) => {
    const touchStart = e.touches[0].clientX;
    setStartX(touchStart);
    if (containerRef.current) {
      setScrollLeft(containerRef.current.scrollLeft); // Get initial scroll position
    }
    setIsMoving(false); // Reset isMoving at the start
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startX === 0 || !containerRef.current) return;

    const touchMove = e.touches[0].clientX;
    const diff = touchMove - startX;

    // If the swipe distance is larger than the threshold, start moving the scroll
    if (Math.abs(diff) > threshold) {
      if (!isMoving) {
        setIsMoving(true); // Mark that the swipe has started
      }

      if (containerRef.current) {
        containerRef.current.style.transition = "none"; // Disable smooth scroll for instant feedback

        // Apply the scroll difference with sensitivity factor
        const newScrollLeft = scrollLeft - diff * sensitivity;

        containerRef.current.scrollLeft = newScrollLeft;
      }
    }
  };

  const handleTouchEnd = () => {
    if (containerRef.current) {
      containerRef.current.style.transition = "scroll-left 0.1s ease-out"; // Smooth out the scroll after the swipe
    }
    setIsMoving(false); // Mark that the swipe is finished
  };

  const scrollLeftByStep = () => {
    if (containerRef.current) {
      const cardWidth =
        containerRef.current?.firstElementChild?.getBoundingClientRect()
          .width || 0; // Get the width of the first card
      containerRef.current.scrollBy({
        left: -cardWidth, // Scroll by one card width to the left
        behavior: "smooth", // Smooth scroll
      });
    }
  };

  const scrollRightByStep = () => {
    if (containerRef.current) {
      const cardWidth =
        containerRef.current?.firstElementChild?.getBoundingClientRect()
          .width || 0; // Get the width of the first card
      containerRef.current.scrollBy({
        left: cardWidth, // Scroll by one card width to the right
        behavior: "smooth", // Smooth scroll
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Add event listeners with the correct types
      container.addEventListener(
        "touchstart",
        handleTouchStart as EventListener
      );
      container.addEventListener("touchmove", handleTouchMove as EventListener);
      container.addEventListener("touchend", handleTouchEnd as EventListener);

      return () => {
        container.removeEventListener(
          "touchstart",
          handleTouchStart as EventListener
        );
        container.removeEventListener(
          "touchmove",
          handleTouchMove as EventListener
        );
        container.removeEventListener(
          "touchend",
          handleTouchEnd as EventListener
        );
      };
    }
  }, [startX, scrollLeft, isMoving]);

  return {
    containerRef,
    scrollLeftByStep,
    scrollRightByStep,
  };
};

export default useSwipeToScroll;
