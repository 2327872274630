import styles from "./styles.module.css";
import { BlockLines, FiveBlock, SevenBlock, SixBlock } from "assets/svg";

export const JourneyAhead = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Building the Future of Learning</h2>
      <div className={styles.leftSide}>
        <BlockLines />
      </div>

      <div className={styles.blocksContainer}>
        <div className={styles.blockWrapper}>
          <div className={styles.blockItem}>
            <FiveBlock />
          </div>
          <div className={styles.textContainer}>
            <p>
              <b>Q1</b> - Launch Night Learning Tools  (AI-driven modules).
            </p>
            <p>
              <b>Q2</b> - Integrate Tokenized Reward System
              (Sleep-n-Learn-to-Earn).
            </p>
            <p>
              <b>Q3</b> - Deploy Language Neural Network (LNN)
            </p>
          </div>
        </div>

        <div className={styles.blockWrapper}>
          <div className={styles.blockItem}>
            <SixBlock />
          </div>
          <div className={styles.textContainer}>
            <p>
              <b>Q1</b> - Enable Fractionalized Ownership for creators &
              learners.
            </p>
            <p>
              <b>Q2</b> - Launch Decentralized Marketplaceor peer-to-peer
              content.
            </p>
            <p>
              <b>Q3</b> - Deploy APIs for Institutions,for seamless rewards &
              gamification.
            </p>
          </div>
        </div>

        <div className={styles.blockWrapper}>
          <div className={styles.blockItem}>
            <SevenBlock />
          </div>
          <div className={styles.textContainer}>
            <p>
              <b>Q1</b> - Expand Decentralized Marketplace globally.
            </p>
            <p>
              <b>Q2</b> - Deploy AI-Powered Personalized Learning with adaptive
              feedback.
            </p>
            <p>
              <b>Q3</b> - Scale with global AI leadership in blockchain
              learning.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
